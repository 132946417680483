
import { defineComponent } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code7 } from "./data";

export default defineComponent({
  name: "with-icon-and-description",
  components: {
    CodeHighlighter
  },
  setup() {
    return {
      code7
    };
  }
});
