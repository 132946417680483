
import { defineComponent } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code3 } from "./data";

export default defineComponent({
  name: "customizable-close-button",
  components: {
    CodeHighlighter
  },
  setup() {
    const hello = () => {
      alert("Hello World!");
    };
    return {
      hello,
      code3
    };
  }
});
